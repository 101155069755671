import ApiService from "@/core/services/ApiService";
import { SpacePaymentSetting } from "@/core/services/responsesModel/spacePaymentSetting"

export function getSpacePaymentSettings(spaceGuid: string): Promise<Array<SpacePaymentSetting>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/PaymentSetting`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function deleteSpacePaymentSettings(spaceGuid: string,spacePaymentSettingGuid:string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/PaymentSetting/${spacePaymentSettingGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}