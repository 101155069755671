<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
    :disabled="isDisabled"
  >
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">名稱</span>
      </label>
      <el-form-item prop="title">
        <el-input v-model="form.title" placeholder="請輸入名稱"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">付款方式</span>
      </label>
      <el-form-item prop="method">
        <el-select v-model="form.method" placeholder="請選擇付款方式">
          <el-option
            v-for="item in paymentMethodOptions"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">appId</span>
      </label>
      <el-form-item prop="appId">
        <el-input v-model="form.appId" placeholder="請輸入appId"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">AppKey</span>
      </label>
      <el-form-item prop="appKey">
        <el-input v-model="form.appKey" placeholder="請輸入AppKey"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">PartnerKey</span>
      </label>
      <el-form-item prop="partnerKey">
        <el-input
          v-model="form.partnerKey"
          placeholder="請輸入PartnerKey"
        ></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">MerchantId</span>
      </label>
      <el-form-item prop="merchantId">
        <el-input
          v-model="form.merchantId"
          placeholder="請輸入MerchantId"
        ></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">正式環境</span>
      </label>
      <el-form-item prop="production">
        <el-select v-model="form.production" placeholder="請選擇正式環境">
          <el-option
            v-for="item in formalOptions"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div v-if="!isDisabled" class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import {
  addTappayPaymentSetting,
  getTappayPaymentSetting,
} from "@/core/services/api/tappaySetting";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["currentItem"],
  setup(prop, { emit }) {
    const isDisabled = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const rules = reactive({
      title: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      method: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "change",
        },
      ],
      appId: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      appKey: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      partnerKey: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      merchantId: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      production: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "change",
        },
      ],
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const defaultForm = {
      title: "",
      method: "",
      appId: "",
      appKey: "",
      partnerKey: "",
      merchantId: "",
      production: "",
    };
    let form = reactive({
      title: "",
      method: "",
      appId: "",
      appKey: "",
      partnerKey: "",
      merchantId: "",
      production: "",
    });
    let paymentMethodOptions = [
      { value: "Offline", text: "線下" },
      { value: "DirectPay", text: "刷卡" },
      { value: "GooglePay", text: "GooglePay" },
      { value: "ApplePay", text: "ApplePay" },
      { value: "LinePay", text: "LinePay" },
      { value: "JkoPay", text: "街口支付" },
      { value: "EasyWallet", text: "悠遊付" },
    ];
    let formalOptions = [
      { value: true, text: "是" },
      { value: false, text: "否" },
    ];

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await addTappayPaymentSetting(currentSpaceGuid!, form).then(
            async () => {
              Object.assign(form, defaultForm);
              await Swal.fire("修改完成!", "", "success");
              emit("setTableData");
            }
          );
        }
      });
    };

    const setDisabled = async () => {
      if (Object.keys(prop.currentItem).length === 0) {
        Object.keys(form).forEach((k) => delete form[k]);
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
        let paymentSetting = await getTappayPaymentSetting(
          currentSpaceGuid!,
          prop.currentItem.paymentSettings.guid
        );
        Object.assign(form, paymentSetting);
        form.title = prop.currentItem.paymentSettings.title;
        form.method = prop.currentItem.paymentSettings.method;
      }
      
    };

    onMounted(() => {
      let addTappayPaymentElement: any = document.getElementById(
        "addTappayPayment-modal"
      );
      addTappayPaymentElement.addEventListener("hidden.bs.modal", function () {
        if (Object.keys(prop.currentItem).length === 0) {
          Object.assign(form, defaultForm);
        }
        formRef.value!.resetFields()
      });
    });

    watch(
      prop.currentItem,
      () => {
        setDisabled();
      },
      { immediate: true, deep: true }
    );

    return {
      form,
      paymentMethodOptions,
      formalOptions,
      formRef,
      submit,
      rules,
      isDisabled,
    };
  },
});
</script>

<style>
</style>