
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import {
  addTappayPaymentSetting,
  getTappayPaymentSetting,
} from "@/core/services/api/tappaySetting";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["currentItem"],
  setup(prop, { emit }) {
    const isDisabled = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const rules = reactive({
      title: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      method: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "change",
        },
      ],
      appId: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      appKey: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      partnerKey: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      merchantId: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "blur",
        },
      ],
      production: [
        {
          required: true,
          message: "請填寫欄位",
          trigger: "change",
        },
      ],
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const defaultForm = {
      title: "",
      method: "",
      appId: "",
      appKey: "",
      partnerKey: "",
      merchantId: "",
      production: "",
    };
    let form = reactive({
      title: "",
      method: "",
      appId: "",
      appKey: "",
      partnerKey: "",
      merchantId: "",
      production: "",
    });
    let paymentMethodOptions = [
      { value: "Offline", text: "線下" },
      { value: "DirectPay", text: "刷卡" },
      { value: "GooglePay", text: "GooglePay" },
      { value: "ApplePay", text: "ApplePay" },
      { value: "LinePay", text: "LinePay" },
      { value: "JkoPay", text: "街口支付" },
      { value: "EasyWallet", text: "悠遊付" },
    ];
    let formalOptions = [
      { value: true, text: "是" },
      { value: false, text: "否" },
    ];

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await addTappayPaymentSetting(currentSpaceGuid!, form).then(
            async () => {
              Object.assign(form, defaultForm);
              await Swal.fire("修改完成!", "", "success");
              emit("setTableData");
            }
          );
        }
      });
    };

    const setDisabled = async () => {
      if (Object.keys(prop.currentItem).length === 0) {
        Object.keys(form).forEach((k) => delete form[k]);
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
        let paymentSetting = await getTappayPaymentSetting(
          currentSpaceGuid!,
          prop.currentItem.paymentSettings.guid
        );
        Object.assign(form, paymentSetting);
        form.title = prop.currentItem.paymentSettings.title;
        form.method = prop.currentItem.paymentSettings.method;
      }
      
    };

    onMounted(() => {
      let addTappayPaymentElement: any = document.getElementById(
        "addTappayPayment-modal"
      );
      addTappayPaymentElement.addEventListener("hidden.bs.modal", function () {
        if (Object.keys(prop.currentItem).length === 0) {
          Object.assign(form, defaultForm);
        }
        formRef.value!.resetFields()
      });
    });

    watch(
      prop.currentItem,
      () => {
        setDisabled();
      },
      { immediate: true, deep: true }
    );

    return {
      form,
      paymentMethodOptions,
      formalOptions,
      formRef,
      submit,
      rules,
      isDisabled,
    };
  },
});
