<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenu2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      選擇服務提供商
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
      <li>
        <button
          class="dropdown-item"
          type="button"
          @click="toTappayBillingSettings()"
        >
          喬睿科技
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  setup() {
    let chooseModal;
    let addTappayPaymentModal;
    const toTappayBillingSettings = () => {
      chooseModal.hide();
      addTappayPaymentModal.show();
    };

    onMounted(() => {
      chooseModal = new Modal(
        document.getElementById("chooseServiceProvider-modal")
      );
      addTappayPaymentModal = new Modal(
        document.getElementById("addTappayPayment-modal")
      );
      
    });

    return {
      toTappayBillingSettings,
    };
  },
});
</script>

<style>
</style>