import ApiService from "@/core/services/ApiService";
import { TappaySetting } from "@/core/services/responsesModel/tappaySetting"

export function addTappayPaymentSetting(spaceGuid: string, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/PaymentSetting/TappaySetting`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getTappayPaymentSetting(spaceGuid: string, spacePaymentSettingGuid: string): Promise<TappaySetting> {
    return new Promise((resolve, reject) => {
        ApiService.get(`/Space/${spaceGuid}/PaymentSetting/${spacePaymentSettingGuid}/TappaySetting`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
