<template>
  <div class="card">
    <div class="card-header border-0 pt-6 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#chooseServiceProvider-modal"
        @click="canscelCurrentItem()"
      >
        新增
      </button>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="true"
        :striped="true"
      >
        <template v-slot:cell-title="{ row: tableData }">
          {{ tableData.title }}
        </template>
        <template v-slot:cell-paymentProvider="{ row: tableData }">
          {{ tableData.paymentProvider }}
        </template>
        <template v-slot:cell-method="{ row: tableData }">
          {{ tableData.method }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-sm btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="setCurrentItem(tableData)"
            >
              功能
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  class="dropdown-item"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#chooseServiceProvider-modal"
                >
                  詳細
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="handelDeleteSpacePaymentSettings()"
                >
                  刪除
                </button>
              </li>
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <!-- 選擇服務提供商modal -->
    <div
      class="modal fade"
      id="chooseServiceProvider-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">選擇服務提供商</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <ChooseServiceProvider></ChooseServiceProvider>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增喬睿帳單modal -->
    <div
      class="modal fade"
      id="addTappayPayment-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">新增喬睿帳單</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <TappayBillingSettings
              :currentItem="currentItem"
              @setTableData="setTableData()"
            ></TappayBillingSettings>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getSpacePaymentSettings,
  deleteSpacePaymentSettings,
} from "@/core/services/api/paymentSetting";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import ChooseServiceProvider from "./components/ChooseServiceProvider.vue";
import TappayBillingSettings from "./components/TappayBillingSettings.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    ChooseServiceProvider,
    TappayBillingSettings,
  },
  setup() {
    const currentItem: any = reactive({});
    const methodMap = {
      Offline: "線下",
      DirectPay: "刷卡",
      GooglePay: "GooglePay",
      ApplePay: "ApplePay",
      SamsungPay: "SamsungPay",
      LinePay: "LinePay",
      JkoPay: "街口支付",
      EasyWallet: "悠遊付",
    };
    const paymentProviderMap = {
      None: "無",
      Tappay: "喬睿科技",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "名稱",
        sortable: true,
      },
      {
        key: "paymentProvider",
        name: "廠商",
        sortable: true,
      },
      {
        key: "method",
        name: "付款方式",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);

    const setTableData = async () => {
      let paymentSettings = await getSpacePaymentSettings(currentSpaceGuid!);
      tableData.splice(0);
      paymentSettings.forEach((o) => {
        tableData.push({
          paymentSettings: o,
          title: o.title,
          paymentProvider: paymentProviderMap[o.paymentProvider],
          method: methodMap[o.method],
          createTime: getLocalTimeString(o.createTime),
        });
      });
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const canscelCurrentItem = () => {
      Object.keys(currentItem).forEach((k) => delete currentItem[k]);
    };

    const handelDeleteSpacePaymentSettings = async () => {
      await Swal.fire({
        title: "您確定要刪除嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let spacePaymentSettingGuid = currentItem.paymentSettings.guid;
          await deleteSpacePaymentSettings(
            currentSpaceGuid!,
            spacePaymentSettingGuid
          );
          await Swal.fire("刪除成功!", "", "success");
          await setTableData();
        }
      });
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      currentItem,
      setCurrentItem,
      canscelCurrentItem,
      handelDeleteSpacePaymentSettings,
    };
  },
});
</script>

<style>
</style>