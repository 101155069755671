
import { defineComponent, onMounted } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  setup() {
    let chooseModal;
    let addTappayPaymentModal;
    const toTappayBillingSettings = () => {
      chooseModal.hide();
      addTappayPaymentModal.show();
    };

    onMounted(() => {
      chooseModal = new Modal(
        document.getElementById("chooseServiceProvider-modal")
      );
      addTappayPaymentModal = new Modal(
        document.getElementById("addTappayPayment-modal")
      );
      
    });

    return {
      toTappayBillingSettings,
    };
  },
});
