
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getSpacePaymentSettings,
  deleteSpacePaymentSettings,
} from "@/core/services/api/paymentSetting";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import ChooseServiceProvider from "./components/ChooseServiceProvider.vue";
import TappayBillingSettings from "./components/TappayBillingSettings.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    ChooseServiceProvider,
    TappayBillingSettings,
  },
  setup() {
    const currentItem: any = reactive({});
    const methodMap = {
      Offline: "線下",
      DirectPay: "刷卡",
      GooglePay: "GooglePay",
      ApplePay: "ApplePay",
      SamsungPay: "SamsungPay",
      LinePay: "LinePay",
      JkoPay: "街口支付",
      EasyWallet: "悠遊付",
    };
    const paymentProviderMap = {
      None: "無",
      Tappay: "喬睿科技",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "title",
        name: "名稱",
        sortable: true,
      },
      {
        key: "paymentProvider",
        name: "廠商",
        sortable: true,
      },
      {
        key: "method",
        name: "付款方式",
        sortable: true,
      },
      {
        key: "createTime",
        name: "建立時間",
        sortable: true,
      },
      {
        key: "operate",
        name: "功能",
        sortable: false,
      },
    ]);

    const setTableData = async () => {
      let paymentSettings = await getSpacePaymentSettings(currentSpaceGuid!);
      tableData.splice(0);
      paymentSettings.forEach((o) => {
        tableData.push({
          paymentSettings: o,
          title: o.title,
          paymentProvider: paymentProviderMap[o.paymentProvider],
          method: methodMap[o.method],
          createTime: getLocalTimeString(o.createTime),
        });
      });
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const canscelCurrentItem = () => {
      Object.keys(currentItem).forEach((k) => delete currentItem[k]);
    };

    const handelDeleteSpacePaymentSettings = async () => {
      await Swal.fire({
        title: "您確定要刪除嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let spacePaymentSettingGuid = currentItem.paymentSettings.guid;
          await deleteSpacePaymentSettings(
            currentSpaceGuid!,
            spacePaymentSettingGuid
          );
          await Swal.fire("刪除成功!", "", "success");
          await setTableData();
        }
      });
    };

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      currentItem,
      setCurrentItem,
      canscelCurrentItem,
      handelDeleteSpacePaymentSettings,
    };
  },
});
